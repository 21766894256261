<template>
  <div>
    <div class="hzhbbox">
      <!--<div class="hztitle">合作伙伴</div>-->
      <!--<select class="linebox"  v-model="selectItem1">-->
      <!--  <option value="0">合作伙伴</option>-->
      <!--  <option v-for="item in partner" :value="item" :key="item.id" >{{item.name}}</option>-->
      <!--</select>-->
      <div class="hztitle">友情链接</div>
      <select class="linebox" v-model="selectItem2">
        <option value="0">友情链接</option>
        <option v-for="item in fLinks" :value="item" :key="item.id" >{{item.name}}</option>
      </select>
      <div class="hztitle">集团网站群</div>
      <select class="linebox" v-model="selectItem3">
        <option value="0">集团网站群</option>
        <option v-for="item in companyList" :value="item" :key="item.id">{{item.name}}</option>
      </select>
    </div>
    <div class="qrbox">
      <img class="arrowtop" src="../image/arrow.png" />
      <div class="zbdw">主办单位:{{footers.versionInfo}}</div>
      <div class="line"></div>
      <div class="kfdh">客服热线：{{footers.hotline}}</div>
      <div class="kfdh">客服电话：{{footers.telephone}}</div>
      <div class="kfdh" v-html="footers.digest"></div>
      <!--<div class="qrimg">-->
      <!--  <img class="qrcode" :src="$baseURL + footers.qrcodeUrl" />-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      partner:[],
      fLinks:[],
      page1:'',
      page2:'',
      companyList:'',
      selectItem1:0,
      selectItem2:0,
      selectItem3:0,
      footers:''
    }
  },
  mounted() {
    this.getPartner()
    this.getFriendLink()
    this.getCompany()
    this.getFooter()
  },
  methods:{
    async getPartner(){
      let res = await this.$api.getPartner();
      this.partner = res.data.data
    },
    async getFriendLink(){
      let res = await  this.$api.getFriendLink();

      this.fLinks = res.data.data
    },
    async getCompany(){
      let res = await this.$api.findCompanyList();
      this.companyList = res.data.data;

    },
    async getFooter(){
      let footer = await this.$api.findFooter();
      this.footers = footer.data.data[0];
      console.log(this.footers,'-ssss')
    }
  },
  watch:{
    selectItem1(newv){
      if(newv){
        window.open(newv.pathUrl);
      }
    },
    selectItem2(newv){
      if(newv){
        window.open(newv.pathUrl);
      }
    },
    selectItem3(newv){
      if(newv){
        window.open(newv.pathUrl);
      }
    }
  }
}
</script>

<style scoped>

.hzhbbox {
  /*margin-top: -16px;*/
  /*width: 320px;*/
  background: #00337D;
  color: #FFFFFF;
  padding-top: 20px;
  padding-bottom: 20px;
}

.hztitle {
  margin-left: 20px;
  font-weight: 600;
  font-size: 15px;
}

.linebox {
  margin: 10px auto;
  padding: 0 10px;
  width: 300px;
  height: 42.5px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: solid 1px #000;
}
.linetext {
  margin: auto auto;
  color: #000000;
  width: 206.5px;
}

.arrow {
  margin: auto auto;
  width: 14px;
  height: 7px;
}

.qrbox {
  /*width: 320px;*/
  background: #09459B;
  padding-bottom: 20px;
  color: #FFFFFF;
  width: 100%;
}

.arrowtop {
  margin-left: 135px;
  width: 50px;
}

.zbdw {
  font-family: Microsoft YaHei;
  font-size: 15px;
  margin: 20px auto;
  text-align: center;

}
.line{
  width: 90%;
  border-bottom: 1px solid #4d8dc5;
  margin: 0 auto;
  margin-bottom: 20px;
}
.qrimg{
  display: flex;
  align-items: center;
  justify-content: center;
}
.qrcode {
  width: 121px;
  height: 121px;

}

.kfdh {
  margin-bottom: 20px;
  font-size: 15px;
  padding: 0px 20px;
}
/deep/.kfdh a{
  color: #fff;
}
</style>
