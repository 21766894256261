<template>
  <div>
    <div class="header">
      <div class="logo">
        <img :src="$baseURL + $store.state.stations.logoUrl" />
      </div>
      <div class="menu" @click="navClick">
        <img src="../image/menu.png" />
        <div class="menuList" style="">
          <div class="content">
            <p class="boldHref">
              <a href="/h5">首页</a>
            </p>
            <p class="closeIcon"><img src="../image/close.png"></p>
          </div>
          <div class="h5-item" v-for="(item) in navs" :key="item.id" >
            <div class="h5-title" @click.stop.prevent="itemClick(item)">
              {{item.name}}
            </div>
            <div class="h5-span" v-if="item.children && item.children.length>0" >
              <div class="flex-span" v-for="(val,i) in item.children" :key="i"  @click.stop.prevent="itemClick(val)">
                <span style="margin-right: 6px">{{val.name}}</span>
                <i class="el-icon-caret-right"></i>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--<transition name="plus-icon">-->
    <!--  <div class="layer" v-if="layer" >-->
    <!--    <div class="layerbox">-->
    <!--      <div v-for="item in navs" :key="item.id" @click="itemClick(item)">-->
    <!--        <div class="layeritem">-->
    <!--          <a href="javascript:;" ><div class="layeritemtitle">{{item.name}}</div></a>-->
    <!--          <i class="el-icon-caret-right"></i>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</transition>-->
  </div>
</template>

<script>
export default {
  data(){
    return {
      navs: [],
      layer:false
    }
  },
  mounted() {
    this.getH5Nav()
  },
  methods:{
    async getH5Nav(){
      let res = await this.$api.getH5Nav();
      this.navs = res.data.data;
      console.log(this.navs,'----');
    },
    navClick() {
      // this.layer = !this.layer
      $('.menuList').slideToggle()
    },
    itemClick(item){
      this.layer = false
      if(item.id == 0){
        return  this.$router.push('/h5')
      }else if(item.name =='联系我们'){
        return this.$router.push("/h5contact")
      }
      return this.$router.push('h5list?pid=' + item.id)
    }
  }
}
</script>

<style scoped>
.header {
  /*margin: 10px auto;*/
  /*width: 320px;*/
  height: 50px;
  display: flex;
  justify-content: space-around;
}

.logo {
  margin-top: 10px;
  width: 250px;
  height: 33.52px;
}

.menu {
  margin-top: 15px;
  width: 22px;
  height: 15.5px;
}
.layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 60vw;
  background: rgba(0,0,0,0.8);
}

.layeritem {
  font-size: 15px;
  font-family: Microsoft YaHei;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid #f1f1f1;
}

.layeritemtitle {
  margin-left: 5px;
  color: #FFFFFF;
}

.layermore {
  font-size: 18px;
  color: #fff;
}

.layerline {
  margin: auto auto;
  width: 100%;
  border: solid 1px #2b2b2c;
}
.plus-icon-enter-active{
  transition: opacity .5s;
}
.plus-icon-enter{
  opacity: 0;
}
.plus-icon-leave-active{
  transition: opacity .5s;
}
.plus-icon-leave-to{
  opacity: 0;
}
.menuList {
  display: none;
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  clear: both;
  background: rgba(0,0,0,0.8);
  z-index: 999999;
  padding: 20px 15px;
}
.closeIcon {
  width: 14px;
  height: 14px;
}
.content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.boldHref a{
  color: #fff !important;
  font-size: 15px;
}
.h5-item{
  border-top: 1px solid #f1f1f1;
  padding-top: 20px;
  color: #fff;
}
.h5-item .h5-title{
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}
.h5-span{
  display: flex;
  flex-wrap: wrap;
}
.h5-span .flex-span{
  width: 33.3%;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: normal;
}
</style>
